import { useAuth0 } from '@auth0/auth0-react';
import { FC, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Loading } from '../components/loading';

const NotLoggedInPages = lazy(() => import('../layouts/not-logged-in-pages'));
const AuthenticatedPages = lazy(() => import('../layouts/authenticated-pages'));
const DashboardPages = lazy(() => import('../layouts/dashboard-pages'));

const RouteSwitcher: FC = () => {
	const { isLoading, isAuthenticated } = useAuth0();
	if (isLoading) {
		return <Loading centerViewPort={true} size="large" />;
	}
	return (
		<Suspense fallback={<Loading centerViewPort={true} size="large" />}>
			{isAuthenticated ? (
				<Switch>
					<Route path="/insights/:companyId">
						<DashboardPages />
					</Route>
					<Route path="/auth-splash">
						<AuthenticatedPages />
					</Route>
					<Route path="*">
						<NotLoggedInPages />
					</Route>
				</Switch>
			) : (
				<Switch>
					<Route path="*">
						<NotLoggedInPages />
					</Route>
				</Switch>
			)}
		</Suspense>
	);
};

export default RouteSwitcher;
