import classNames from 'classnames';
import { FC } from 'react';

import { Spinner } from 'reactstrap';

export interface ILoadingProps {
	centerViewPort?: boolean;
	size?: 'small' | 'medium' | 'large';
}

// TODO Add support for wrapped via props: withinContent? => true? Put loading into <div className="content"></div>
export const Loading: FC<ILoadingProps> = ({ centerViewPort, size }) => {
	const sizeWithDefault = size || 'medium';

	return (
		<div
			className={classNames(
				{ 'vh-100': !!centerViewPort },
				{ 'h-100': !centerViewPort },
				'w-100',
				'd-flex',
				'justify-content-center',
				'align-items-center',
				'text-primary',
			)}
		>
			<Spinner
				animation="border"
				role="status"
				style={{
					width: sizeWithDefault === 'large' ? '4rem' : sizeWithDefault === 'small' ? '1rem' : '2rem',
					height: sizeWithDefault === 'large' ? '4rem' : sizeWithDefault === 'small' ? '1rem' : '2rem',
				}}
			/>
		</div>
	);
};
