import { ApolloClient, ApolloProvider, createHttpLink, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { FC, ReactNode, useEffect, useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from '../loading';
import { GET_FILTERS } from '../filters/gql';
import { DefaultCacheService } from '../../store/default-cache-service';
import { GET_CURRENT_COMPANY, GET_CURRENT_USER } from '../../gql';

interface IOwnProps {
	children: ReactNode;
}

const typeDefs = gql`
	extend input FiltersInput {
		startDate: Date
		endDate: Date
		selectedProducts: [String]
	}
`;

export const AuthApolloProvider: FC<IOwnProps> = ({ children }: IOwnProps) => {
	const { isLoading, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();

	const fetchToken = (() => {
		let token: string | undefined;
		return async () => {
			if (!token) token = isAuthenticated ? await getAccessTokenSilently() : undefined;
			return token;
		};
	})();

	const httpLink = createHttpLink({
		uri: process.env.REACT_APP_API_ENDPOINT,
	});

	const authLink = setContext(async (_, { headers }) => {
		// get the authentication token from local storage if it exists
		const token = await fetchToken();

		// return the headers to the context so httpLink can read them
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${token}` : '',
			},
		};
	});

	const client = useMemo(
		() =>
			new ApolloClient({
				connectToDevTools: process.env.NODE_ENV === 'development',
				link: authLink.concat(httpLink),
				cache: DefaultCacheService.getInstance().getCache(),
				resolvers: {
					Mutation: {
						updateFilters: (_root, variables: { input: Record<string, unknown> }, { cache }) => {
							const previousStateCache = cache.readQuery({ query: GET_FILTERS });

							const filters = {
								...previousStateCache.filters,
								...variables.input,
							};
							cache.writeQuery({
								query: GET_FILTERS,
								data: { filters },
							});
							return filters;
						},
					},
				},
				typeDefs,
			}),
		[authLink, httpLink],
	);

	useEffect(() => {
		const updateCache = async (): Promise<void> => {
			if (!isAuthenticated) return;
			const claims = await getIdTokenClaims();
			const id = claims[`${process.env.REACT_APP_AUTH0_DOMAIN_PREFIX}/id`];
			const accessRights = claims[`${process.env.REACT_APP_AUTH0_DOMAIN_PREFIX}/accessRights`];
			const firstName = claims[`${process.env.REACT_APP_AUTH0_DOMAIN_PREFIX}/firstName`];
			const lastName = claims[`${process.env.REACT_APP_AUTH0_DOMAIN_PREFIX}/lastName`];
			const fullName = claims[`${process.env.REACT_APP_AUTH0_DOMAIN_PREFIX}/fullName`];
			const avatar = claims.picture;

			client.writeQuery({
				query: GET_CURRENT_USER,
				data: {
					currentUser: {
						__typename: 'User',
						id: id || '',
						firstName: firstName || '',
						lastName: lastName || '',
						fullName: fullName || '',
						avatar: avatar || '',
					},
				},
			});

			client.writeQuery({
				query: GET_CURRENT_COMPANY,
				data: {
					currentCompany: {
						__typename: 'Company',
						name: accessRights?.[0]?.companyName || '', // If the user is not logged in access right don't exist. Hence: ?.[0]
						id: accessRights?.[0]?.companyId || '', // If the user is not logged in access right don't exist. Hence: ?.[0]
					},
				},
			});
		};
		updateCache();
	}, [client, getIdTokenClaims, isAuthenticated]);
	if (isLoading) return <Loading centerViewPort={true} size="large" />;
	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
