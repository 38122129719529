import gql from 'graphql-tag';

export const FETCH_PRODUCTS_SEARCH = gql`
	query findProductVariants($input: PaginatedQueryInput!) {
		findProductVariants(input: $input) {
			edges {
				cursor
				node {
					id
					name
					sku
					channelProductId
					platform
					channel
				}
			}
			pageInfo {
				hasNextPage
			}
		}
	}
`;

export const GET_SELECTED_PRODUCTS = gql`
	query getSelectedProducts {
		filters @client {
			selectedProducts {
				id
				name
				sku
				channelProductId
			}
		}
	}
`;

export const GET_DURATION = gql`
	query getSelectedProducts {
		filters @client {
			startDate
			endDate
		}
	}
`;

export const GET_FILTERS = gql`
	query getFilters {
		filters @client {
			startDate
			endDate
			selectedProducts {
				id
				name
				sku
				channelProductId
			}
		}
	}
`;

export const UPDATE_FILTERS = gql`
	mutation UpdateFilters($input: FiltersInput) {
		updateFilters(input: $input) @client
	}
`;
