import gql from 'graphql-tag';

export const GET_CURRENT_COMPANY = gql`
	query getCurrentCompany {
		currentCompany @client {
			name
			id
		}
	}
`;

export interface ICurrentCompanyResultVariables {
	name: string;
	id: string;
}

export interface IGetCurrentCompanyQueryResults {
	currentCompany: ICurrentCompanyResultVariables;
}

export const GET_CURRENT_USER = gql`
	query getCurrentUser {
		currentUser @client {
			id
			firstName
			lastName
			fullName
			avatar
		}
	}
`;

export interface ICurrentUserResultVariables {
	id?: string;
	firstName?: string;
	lastName?: string;
	fullName?: string;
	avatar?: string;
}

export interface IGetCurrentUserQueryResults {
	currentUser: ICurrentUserResultVariables;
}
