import { InMemoryCache } from '@apollo/client';
import { startOfDay, sub } from 'date-fns';
import { GET_FILTERS } from '../components/filters/gql';

export class DefaultCacheService {
	private static _defaultCacheService: DefaultCacheService;
	private _cache: InMemoryCache;

	private constructor() {
		this._cache = new InMemoryCache({
			typePolicies: {
				Query: {
					fields: {},
				},
			},
			// Todo: the below approach is deprecated. Its commented out for now, to test if the app works without it.
			// // since our ID fields are capitalized instead of lowercase we must tell Apollo to use the
			// // uppercase ID field as the cache key (by default it uses the lowercase id field).
			// dataIdFromObject: (object: StoreObject): string | undefined => {
			// 	if (object.id) return `${object.__typename}:${object.id}`;
			// 	return defaultDataIdFromObject(object); // fall back to default to
			// },
		});
		this.setDefaultCache();
	}

	public getCache(): InMemoryCache {
		return this._cache;
	}

	public setDefaultCache(): void {
		const isSmallScreen = window.outerWidth <= 992;

		const today = startOfDay(Date.now());
		const aWeekAgo = sub(today, { days: 7 });
		const aMonthAgo = sub(today, { days: 30 });

		this._cache.writeQuery({
			query: GET_FILTERS,
			data: {
				filters: {
					__typename: 'filters',
					startDate: isSmallScreen ? aWeekAgo : aMonthAgo,
					endDate: today,
					selectedProducts: [],
					returningCustomers: false,
					minAmountOfOrders: null,
					maxAmountOfOrders: null,
				},
			},
		});
	}

	public static getInstance(): DefaultCacheService {
		if (!this._defaultCacheService) this._defaultCacheService = new DefaultCacheService();
		return this._defaultCacheService;
	}
}
