import { render } from 'react-dom';
import AppSetup from './app-setup';

render(<AppSetup />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
// reportWebVitals(console.log);

const wavingHandEmoji = String.fromCodePoint(0x1f44b);
console.log(`Welcome to Peter's Meters! ${wavingHandEmoji}`);
