import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './assets/scss/index.scss';
import { ReactElement } from 'react';
import { Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { AuthApolloProvider } from './components/auth/auth-apollo-provider';
import RouteSwitcher from './routers/route-switcher';
import { lightTheme } from './theme/light';
import history from './utils/history';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: { returnTo?: string }): void => {
	const redirectToURL = appState && appState.returnTo ? appState.returnTo : '/auth-splash/select-company';
	history.push(redirectToURL);
};

const AppSetup = (): ReactElement => {
	return (
		<Auth0Provider
			domain={process.env.REACT_APP_AUTH0_DOMAIN}
			clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
			redirectUri={window.location.origin}
			audience={process.env.REACT_APP_AUTH0_AUDIENCE}
			onRedirectCallback={onRedirectCallback}
		>
			<AuthApolloProvider>
				<ThemeProvider theme={lightTheme}>
					<Router history={history}>
						<RouteSwitcher />
					</Router>
				</ThemeProvider>
			</AuthApolloProvider>
		</Auth0Provider>
	);
};

export default AppSetup;
