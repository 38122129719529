import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
	backgroundColor: '#ffffff',
	fontColor: '#66615b',
	colors: {
		primary: '#5ae9c8',
		secondary: '#2f2684',
		caption: '#9a9a9a',
		warning: '#856404',
		error: '#ff0033',
		success: '#155724',
		info: '#004085',
		white: '#ffffff',
	},
	backgroundColors: {
		primary: '#5ae9c8',
		secondary: '#66615b',
		warning: '#fff3cd',
		error: '#f8d7da',
		success: '#d4edda',
		info: '#cce5ff',
	},
	backgroundHoverColors: {
		primary: '#5ae9c8',
	},
};
